#homepage-container {
  // border: 1px solid orange;
  width: 99vw;
  height: 99vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
}

#logo-and-name-container {
  // border: 1px solid yellow;
  width: 100%;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

#logo-container {
  // border: 1px solid green;
  width: 300px;
  // height: 100%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

#act-logo {
  // width: 100px;
  // justify-self: right;
}

#act-name {
  padding-left: 10px;
  text-align: center;
}

#image1-container {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

#description-container {
  // border: 1px solid purple;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: Designosaur, sans-serif;
  font-size: 2rem;
  margin-top: 50px;
}

#cta-container {
  // border: 5px solid green;
  background-color: $white-full-opacity;
  width: 100%;
  min-height: 100vh;
}

#image2-container {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

#benefits-container {
  // border: 1px solid red;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: $dark-blue1-full-opacity;
  font-size: 1rem;
  margin-top: 75px;

  li {
    margin-left: 30px;
    margin-top: 20px;
    list-style-type: circle;
  }
}

#cta-button-container {
  // border: 1px solid blue;
  background-color: $dark-blue1-full-opacity;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: $white-full-opacity;
  // font-weight: bold;
  font-size: 1.5rem;
  font-family: Designosaur, sans-serif;
}

#cta-button-container:hover {
  background-color: $light-blue1-full-opacity;
  color: $white-full-opacity;
  cursor: pointer;
}

@media all and (max-width: 800px) {
  #homepage-container {
    // border: 1px solid orange;
    // width: 99vw;
    // height: 99vh;
    // display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    // align-items: flex-start;
  }
}
