/// BORDERS STYLES ////

.border-none {
  border: none;
}

.border-1px-solid-white {
  border: 1px solid $white-full-opacity;
}

.border-red {
  border: 1px solid $red-full-opacity;
}

.border-green {
  border: 10px solid $green-full-opacity;
}

.border-white {
  border: 2px solid $white-full-opacity;
}

.border-radius-50pc {
  border-radius: 50%;
}

.border-radius-5px {
  border-radius: 5px;
}

.border-radius-10px {
  border-radius: 10px;
}

.border-bottom-1px-solid-black {
  border-bottom: 1px solid $black-full-opacity;
}

.border-bottom-1px-solid-grey {
  border-bottom: 1px solid $white-half-opacity;
}

.border-bottom-1px-solid-white {
  border-bottom: 1px solid $white-full-opacity;
}

.border-3px-solid-white {
  border: 3px solid $white-full-opacity;
}

.border-3px-solid-red {
  border: 3px solid $red-full-opacity;
}

.box-shadow {
  box-shadow: 1px 1px 0px 0px $white-full-opacity;
}

/// BORDERS STYLES END //////////

////////// MARGINS & PADDINGS STYLES /////////////
.div-center {
  // border: 1px solid red;
  margin-left: auto;
  margin-right: auto;
}

.margin-1px {
  margin: 10px;
}

.margin-left-1px {
  margin-left: 1px;
}

.margin-left-0-1vw {
  margin-left: 0.1vw;
}

.margin-left-0-5vw {
  margin-left: 0.5vw;
}

.margin-left-1vw {
  margin-left: 1vw;
}

.margin-left-2vw {
  margin-left: 2vw;
}

.margin-left-3vw {
  margin-left: 3vw;
}

.margin-left-5vw {
  margin-left: 5vw;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-right-0-1vw {
  margin-right: 0.1vw;
}

.margin-right-0-5vw {
  margin-right: 0.5vw;
}

.margin-right-1vw {
  margin-right: 1vw;
}

.margin-right-1-5vw {
  margin-right: 1.5vw;
}

.margin-right-2vw {
  margin-right: 2vw;
}

.margin-right-3vw {
  margin-right: 3vw;
}

.margin-top-200px {
  margin-top: 200px;
}

.margin-top-0-5vh {
  margin-top: 0.5vh;
}

.margin-top-1vh {
  margin-top: 1vh;
}
.margin-top-2vh {
  margin-top: 2vh;
}

.margin-top-5vh {
  margin-top: 5vh;
}

.margin-top-10vh {
  margin-top: 10vh;
}

.margin-top-30px {
  margin-top: 30px;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-bottom-20px {
  margin-bottom: 20px;
}

.margin-bottom-30px {
  margin-bottom: 30px;
}

.margin-bottom-0-5vh {
  margin-bottom: 0.5vh;
}

.margin-bottom-1vh {
  margin-bottom: 1vh;
}

.margin-bottom-2vh {
  margin-bottom: 2vh;
}

.margin-bottom-5vh {
  margin-bottom: 5vh;
}

.margin-bottom-10vh {
  margin-bottom: 15vh;
}

.padding-1px {
  padding: 1px;
}

.padding-3px {
  padding: 3px;
}

.padding-30px {
  padding: 30px;
}

.padding-left-1px {
  padding-left: 1px;
}

.padding-left-0-5vw {
  padding-left: 0.5vw;
}

.padding-left-1vw {
  padding-left: 1vw;
}

.padding-left-2vw {
  padding-left: 2vw;
}

.padding-right-0-5vw {
  padding-right: 0.5vw;
}

.padding-right-1vw {
  padding-right: 1vw;
}

.padding-right-2vw {
  padding-right: 2vw;
}

.padding-right-10vw {
  padding-right: 10vw;
}

.padding-bottom-1vh {
  padding-bottom: 1vh;
}

.padding-bottom-2vh {
  padding-bottom: 2vh;
}

.padding-top-2px {
  padding-top: 2px;
}

.padding-top-2-5px {
  padding-top: 2.5px;
}

.padding-top-5px {
  padding-top: 5px;
}

.padding-top-0-5vh {
  padding-top: 0.5vh;
}

.padding-top-1vh {
  padding-top: 1vh;
}

.padding-top-1-5vh {
  padding-top: 1.5vh;
}

.padding-top-3vh {
  padding-top: 3vh;
}

///////// WIDTHS AND HEIGHTS STYLES /////

.width-100pc {
  width: 100%;
}

.width-50pc {
  width: 50%;
}

.width-30pc {
  width: 30%;
}

.width-30vw {
  width: 30vw;
}

.width-50vw {
  width: 50vw;
}

.width-fit-content {
  width: fit-content;
}

.height-5vh {
  height: 5vh;
}

.height-50vh {
  height: 50vh;
}

///////// WIDTHS AND HEIGHTS STYLES END /////

/////// ALIGNMENTS STYLES /////////

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.align-self-start {
  align-self: start;
}

.align-self-center {
  align-self: center;
}

/////// ALIGNMENTS STYLES END /////////

//////// POSITIONS STYLES ////////

.position-relative {
  position: relative;
}

.position-absolute-up-right {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: +1;
}

.display-hidden {
  display: hidden;
}

//////// POSITIONS STYLES END ////////

//////// BACKGROUND-COLORS ///////////
.background-color-white {
  background-color: $white-full-opacity;
}

.background-color-red {
  background-color: $red-full-opacity;
}

.background-color-green {
  background-color: $green-full-opacity;
}

.background-color-orange {
  background-color: $orange-full-opacity;
}

.background-color-light-blue1 {
  background-color: $light-blue1-full-opacity;
}
