// Styles for navbar //
#navbar-container {
  // border: 1px solid orange;
  background-color: $dark-blue1-full-opacity;
  // border-right: 1px solid $white-half-opacity;
  width: auto;
  min-height: 100vh;
}

#header-logo-and-name-container {
  // border: 1px solid red;
  padding-top: 5px;
  // height: auto;
}

#header-logo-container {
  // border: 1px solid green;
  width: 150px;
  // height: 100%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  //   padding-top: 10px;
}

#header-act-name {
  // border: 1px solid white;
  padding-left: 10px;
  text-align: center;
  font-size: 1rem;
}

#navbar-tool-name-container {
  // border: 1px solid orange;
  background-color: $white-full-opacity;
  height: fit-content;
  font-size: 1.5rem;
  text-align: center;
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar-tool-name {
  font-size: 1.2rem;
  color: $dark-blue1-full-opacity;
  // font-weight: bold;
}

#navbar-buttons-container {
  // border: 1px solid yellow;
  margin-top: 50px;
}

#navbar-button-logout-container {
  width: fit-content;
  background-color: $red-full-opacity;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  cursor: pointer;
}

#navbar-button-logout {
  display: flex;
  align-self: center;
  padding: 7.5px;
  font-size: 1.5rem;
  transition: font-size 0.25s ease;
}

#navbar-button-logout:hover {
  font-size: 2.5rem;
}

.navbar-button-container {
  //   border: 1px solid violet;
  position: relative;
  width: fit-content;
  background-color: $light-blue2-full-opacity;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  cursor: pointer;

  &.navbar-button-is-selected {
    background-color: $orange-full-opacity;
    color: $white-full-opacity;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -15px; /* Ajuster la position de la flèche */
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 13px solid transparent; /* Taille de la flèche */
      border-bottom: 13px solid transparent; /* Taille de la flèche */
      border-left: 10px solid $orange-full-opacity; /* Couleur de la flèche */
    }
  }
}

// .navbar-button-container:hover {
//   background-color: $orange-half-opacity;
//   color: $white-full-opacity;
//   font-weight: bold;
// }

.navbar-button {
  display: flex;
  align-self: center;
  padding: 7.5px;
  font-size: 1.5rem;
  transition: font-size 0.25s ease;
}

.navbar-button:hover {
  font-size: 2.5rem;
}

#menu-illustration-container {
  // border: 1px solid red;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  // align-content: flex-start;
}

#menu-illustration {
  transform: scaleX(-1);
}

// Styles for navbar END //

@media all and (max-width: 800px) {
  #navbar-container {
    min-height: 0;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-bottom: 10px;
  }

  #navbar-buttons-container {
    // border: 1px solid yellow;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // justify-self: cent;
    width: 200%;
  }

  #navbar-tool-name-container {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
