/* Reset all default styles */
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
ul,
ol,
li,
input,
label,
form,
button {
  min-width: none;
  max-width: none;
  min-height: none;
  max-height: none;
  margin: 0;
  padding: 0;
  border: none;
  font-family: Nunito-VariableFont, sans-serif;
  color: $white-full-opacity;
  font-style: normal;
  text-decoration: none;
  list-style: none;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]),
video:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
  max-width: 100%;
  height: auto;
  display: inline-block;
}
