////////////////////////////////////////
// Styles for send reset instructions //

#send-reset-instructions-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#send-reset-instructions-form {
  // border: 1px solid red;
  background-color: $white-full-opacity;
  border-radius: 10px;
  width: 400px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  text-align: center;
}

#submit-buttons-container {
  // border: 1px solid blue;
  display: flex;
  justify-content: space-around;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#send-button {
  // border: 1px solid purple;
  background-color: $light-blue1-full-opacity;
  color: $white-full-opacity;
  width: 75px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  // font-weight: bold;
  font-family: Designosaur, sans-serif;
}

#send-button:hover {
  // border: 1px solid purple;
  background-color: $light-blue1-half-opacity;
  color: $white-full-opacity;
}

#close-button {
  // border: 1px solid purple;
  background-color: $red-full-opacity;
  color: $white-full-opacity;
  width: 75px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  // font-weight: bold;
  font-family: Designosaur, sans-serif;
}

#close-button:hover {
  // border: 1px solid purple;
  background-color: $red-half-opacity;
  color: $white-full-opacity;
}

// Styles for send reset instructions END //
////////////////////////////////////////////

////////////////////////////////////
// Styles for reset password form //
#reset-password-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#reset-password-form {
  // border: 1px solid red;
  background-color: $white-full-opacity;
  border-radius: 10px;
  width: 400px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  text-align: center;
}

.reset-password-input-container {
  // border: 1px solid blue;
  height: 50px;
  line-height: 50px;
}

.reset-password-input {
  color: $dark-blue1-full-opacity;
  width: 100%;
  background-color: none;
  border-bottom: 1px solid $dark-blue1-full-opacity;
}

#reset-password-submit-button-container {
  // border: 1px solid red;
  background-color: $light-blue1-full-opacity;
  border-radius: 5px;
  text-align: center;
  color: $white-full-opacity;
  cursor: pointer;
  margin-top: 25px;
  font-size: 1.5rem;
}

#reset-password-submit-button {
  width: 100%;
  font-family: Designosaur, sans-serif;
}

// Styles for reset password form END //
////////////////////////////////////////

///// MEDIA QUERIES /////////
@media all and (max-width: 700px) {
  #send-reset-instructions-form {
    width: 85%;
  }

  #reset-password-form {
    width: 60%;
  }
}

@media all and (max-width: 400px) {
  #reset-password-form {
    width: 80%;
  }
}
