#audit-rgpd-dashboard-container {
  // border: 1px solid salmon;
  margin-top: 50px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

#audit-rgpd-dashboard-charts-and-buttons-container {
  // border: 1px solid yellowgreen;
  display: grid;
  grid-template-columns: 250px auto;
  margin-top: 50px;
}

#audit-rgpd-dashboard-buttons-container {
  // border: 1px solid purple;
}
.audit-rgpd-dashboard-button-container {
  // border: 3px solid purple;
}

.audit-rgpd-dashboard-button {
  // border: 1px solid blue;
  // background-color: $white;
  width: 150px;
  height: 50px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-family: Designosaur, sans-serif;
  margin-bottom: 10px;
}

#audit-rgpd-questions-button {
  background-color: $light-blue2-full-opacity;
}

#audit-rgpd-questions-button:hover {
  // border: 1px solid yellowgreen;
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
}

// #results-button {
//   background-color: $green-full-opacity;
// }

// #results-button:hover {
//   // border: 1px solid yellowgreen;
//   background-color: $white-full-opacity;
//   color: $dark-blue1-full-opacity;
// }

#audit-rgpd-raz-button {
  background-color: $orange-full-opacity;
}

#audit-rgpd-raz-button:hover {
  // border: 1px solid yellowgreen;
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
}

#audit-rgpd-pdf-button {
  // border: 1px solid yellowgreen;
  background-color: $red-full-opacity;
  color: $white-full-opacity;
}

#audit-rgpd-pdf-button:hover {
  // border: 1px solid yellowgreen;
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
}

////////// STYLES FOR CHARTS ////
#audit-rgpd-charts-container {
  // border: 3px solid orange;
  // background-color: $white-full-opacity;
  width: 99%;
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: 50px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.single-audit-rgpd-chart-container {
  border: 1px solid $white-full-opacity;
  background-color: $dark-blue3-full-opacity;
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px 10px 0 0;
  // justify-content: center;
  // align-content: center;

  // height: auto;
  //   margin-left: 1vw;
  //   margin-right: 1vw;
  // margin-bottom: 10vh;
  // padding-bottom: 1vh;
  // padding: 10px;
  // padding-left: 10px;

  cursor: pointer;
}

.single-audit-rgpd-chart-container:hover {
  background-color: $light-blue1-half-opacity;
}

.audit-rgpd-chart-title-container {
  // border: 3px solid rgb(0, 255, 72);
  background-color: $white-full-opacity;
  border-radius: 10px 10px 0 0;
  // width: 100%;
  height: auto;
  // line-height: 50px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 5px;
}

.audit-rgpd-title-header {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.audit-rgpd-category-container {
  // border: 1px solid blue;

  color: $dark-blue1-full-opacity;
  text-align: center;
  font-family: Designosaur, sans-serif;
}

.audit-rgpd-total-questions-container {
  // border: 1px solid black;
  display: grid;
  grid-template-columns: auto auto;
  place-items: center;
  width: fit-content;
}

.audit-rgpd-total-questions {
  // border: 1px solid orange;
  // width: fit-content;
  // position: absolute;
  font-size: 0.8rem;
  color: $dark-blue1-full-opacity;
}

.audit-rgpd-completion-icon-container {
  // position: relative;
  // right: -30px;
  padding-left: 5px;
}

.answers-quantities-container {
  // border: 1px solid red;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

.answers-quantity-yes {
  background-color: $green-full-opacity;
  font-family: Designosaur, sans-serif;
  color: $white-full-opacity;
  border-radius: 10px;
  padding: 3px;
}

.answers-quantity-no {
  background-color: $red-full-opacity;
  font-family: Designosaur, sans-serif;
  color: $white-full-opacity;
  border-radius: 10px;
  padding: 3px;
}

.answers-quantity-na {
  background-color: $white-full-opacity;
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
  border-radius: 10px;
  padding: 3px;
}

.audit-rgpd-chart-container {
  // border: 3px solid rgb(0, 234, 255);
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  // cursor: pointer;
}

////////// STYLES FOR CHARTS END ////

////////Styles for AuditRGPD QUESTIONS ////////
#audit-rgpd-questions-container {
  //   border: 5px solid orange;
  width: auto;
  min-height: 100vh;
  padding-left: 10px;
  padding-right: 10px;
}
#audit-rgpd-questions-list-container {
  // border: 1px solid red;
  margin-top: 50px;

  h1 {
    text-align: center;
    font-size: 1rem;
    // font-family: Designosaur, sans-serif;
    margin-bottom: 10px;
  }
}

#first-time-access-container {
  // border: 1px solid greenyellow;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

#first-time-access-button {
  // border: 1px solid white;
  background-color: $green-full-opacity;
}

#first-time-access-button:hover {
  // border: 1px solid white;
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
}

#audit-rgpd-questions-form {
  // border: 1px solid lightpink;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
}

.audit-rgpd-single-question-container {
  // border: 5px solid orange;
  background-color: $white-full-opacity;
  width: 600px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  padding-bottom: 10px;
}

// .audit-rgpd-single-question-container:hover {
//   background-color: $white-half-opacity;
// }

.audit-rgpd-single-question-header {
  // border: 5px solid yellowgreen;
  background-color: $light-blue3-full-opacity;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.audit-rgpd-question-id-container {
  color: $white-full-opacity;
  // padding: 5px;
  font-size: 1.25rem;
  font-family: Designosaur, sans-serif;
}

.audit-rgpd-question-content-container {
  // background-color: red;
  color: $dark-blue1-full-opacity;
  font-family: Designosaur, sans-serif;
  font-size: 0.9rem;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 2%;
  text-align: justify;
}

.audit-rgpd-answer-container {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

.audit-rgpd-input-container {
  // border: 1px solid red;
}

.audit-rgpd-input-label {
  // border: 1px solid red;
  cursor: pointer;
  color: $dark-blue1-full-opacity;
  padding-left: 5px;
  font-family: Designosaur, sans-serif;
}

.audit-rgpd-comment-container {
  // border: 1px solid red;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 5px;
}

.audit-rgpd-comment-label {
  // border: 1px solid blue;
  color: $dark-blue1-full-opacity;
  font-family: Designosaur, sans-serif;
}

.audit-rgpd-comment-input {
  // border: 1px solid blue;
  width: 98%;
}

////////Styles for AuditRGPD QUESTIONS END ///////

#audit-rgpd-questions-illustration-container {
  // border: 1 px solid red;
  position: absolute;
  top: 275px;
  right: 80px;
}

.audit-rgpd-questions-illustration {
  width: 200px;
  // transform: scaleX(-1);
}

////////Styles for AuditRGPD Progress ////////
#audit-rgpd-progress-container {
  border: 3px solid $dark-blue3-full-opacity;
  background-color: $light-blue2-full-opacity;
  display: grid;
  grid-template-columns: auto auto auto;
  place-items: center;
  position: fixed;
  top: 255px;
  left: 245px;
  width: fit-content;
  border-radius: 10px;
  padding: 10px;
}

#audit-rgpd-progress-header-container {
  border-right: 1px solid $white-full-opacity;
  display: flex;
  flex-direction: column;
  align-self: start;
  padding-right: 10px;
}

#audit-rgpd-progress-title-container {
}

#audit-rgpd-progress-title {
  // border: 1px solid green;
  background-color: $red-full-opacity;
  width: 100%;
  height: fit-content;
  // border-bottom: 1px solid $dark-blue1-full-opacity;
  // background-color: $light-blue2-full-opacity;
  color: $white-full-opacity;
  // border-radius: 20px 20px 0 0;
  font-weight: normal;
  text-align: center;
  font-family: Designosaur, sans-serif;
  display: flex;
  align-self: start;
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
}

#audit-rgpd-back-to-top-container {
  // border: 1px solid red;
  color: $white-full-opacity;
  // justify-self: center;
  // text-align: center;
  display: flex;
  justify-content: center;
  // align-items: center;
  // justify-content: space-between;
  padding-top: 10px;
  // padding-bottom: 0.5vh;
  // padding-left: 20px;
  cursor: pointer;
}

#audit-rgpd-all-status-container {
  // border: 5px solid purple;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  padding-left: 10px;
  // padding-right: 5px;
  // margin-left: 10px;
  // margin-right: 10px;
}

.one-status-container {
  // border: 1px solid $black-full-opacity;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 1px;
  text-align: center;
}

.status-not-answered {
  // margin-top: -0.25vh;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: $dark-blue1-full-opacity;
}

.status-answered {
  // margin-top: -0.25vh;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: $white-full-opacity;
}

////////Styles for AuditRGPD Progress END ////////

////////Styles for AuditRGPD validation modal ////////
#audit-rgpd-validation-prompt-modal {
  // border: 1px solid orange;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#audit-rgpd-validation-prompt {
  background-color: $white-full-opacity;
  border-radius: 10px;
  width: 400px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  text-align: center;
}

#audit-rgpd-validation-prompt-buttons-container {
  // border: 1px solid blue;
  display: flex;
  justify-content: space-around;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#audit-rgpd-validation-prompt-submit-button {
  // border: 1px solid purple;
  background-color: $light-blue1-full-opacity;
  color: $white-full-opacity;
  width: 75px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  // font-weight: bold;
  font-family: Designosaur, sans-serif;
}

#audit-rgpd-validation-prompt-submit-button:hover {
  // border: 1px solid purple;
  background-color: $light-blue1-half-opacity;
  color: $white-full-opacity;
}

#audit-rgpd-validation-prompt-cancel-button {
  // border: 1px solid purple;
  background-color: $red-full-opacity;
  color: $white-full-opacity;
  width: 75px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  // font-weight: bold;
  font-family: Designosaur, sans-serif;
}

#audit-rgpd-validation-prompt-cancel-button:hover {
  // border: 1px solid purple;
  background-color: $red-half-opacity;
  color: $white-full-opacity;
}
////////Styles for AuditRGPD validation modal END ////////

//////// STYLES FOR AuditRGPDAnswersAndCommentsModal ///////////
#audit-rgpd-answers-and-comments-modal {
  // border: 1px solid red;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#audit-rgpd-answers-and-comments-container {
  // border: 1px solid $white-full-opacity;
  border-left: 1px solid $white-half-opacity;
  background-color: $dark-blue1-full-opacity;
  color: $white-full-opacity;
  margin-left: auto;
  // margin-right: auto;
  // margin-top: 10vh;
  width: 50%;
  min-height: 100vh;
  // border-radius: 10px;
  padding: 2px;
  // overflow-y: auto;
}

#audit-rgpd-answers-and-comments-title-container {
  h2 {
    font-family: Designosaur, sans-serif;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.25rem;
  }
}

#audit-rgpd-answers-and-comments {
  // border: 1px solid blue;
  // background-color: $white-half-opacity;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
}

.audit-rgpd-single-answer-and-comment-container {
  background-color: $white-full-opacity;
  margin-bottom: 30px;
}

.audit-rgpd-question-container {
  // border: 5px solid red;
  margin-bottom: 0px;
  color: $dark-blue1-full-opacity;
}

.audit-rgpd-answer-container {
  // border: 1px solid red;
  // margin-bottom: 25px;
}

.not-yet-answered {
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
  font-style: italic;
}

.answered {
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
  // padding: 3px;
}

.answer-yes{
  border-left: 10px solid $green-full-opacity;
  padding-left: 5px;
}

.answer-no{
  border-left: 10px solid $red-full-opacity;
  padding-left: 5px;
}

.answer-na{
  border-left: 10px solid $dark-blue1-full-opacity;
  padding-left: 5px;
}

//////// STYLES FOR AuditRGPDAnswersAndCommentsModal END ///////////
@media all and (max-width: 1200px) {
  #audit-rgpd-progress-container {
    // top: 1%;
    left: 1%;
    // transform: translateX(-50%);
  }

  // #audit-rgpd-all-status-container {
  //   grid-template-columns: repeat(33, auto);
  //   // grid-template-rows: 1fr 1fr;
  // }
}

@media all and (max-width: 1150px) {
  #audit-rgpd-charts-container {
    grid-template-columns: 1fr;
  }

  .single-audit-rgpd-chart-container {
    margin-bottom: 30px;
  }
}

@media all and (max-width: 610px) {
  #audit-rgpd-charts-container {
    margin-top: 30px;
  }

  .single-audit-rgpd-chart-container {
    width: 99%;
  }
}

@media all and (max-width: 1000px) {
  #audit-rgpd-answers-and-comments-container {
    width: 75%;
  }
}

@media all and (max-width: 800px) {
  #audit-rgpd-progress-container {
    top: 1%;
    left: 1%;
    // transform: translateX(-50%);
  }

  #audit-rgpd-all-status-container {
    grid-template-columns: repeat(20, auto);
    // grid-template-rows: 1fr 1fr;
  }
}

@media all and (max-width: 610px) {
  #audit-rgpd-dashboard-charts-and-buttons-container {
    grid-template-columns: none;
    grid-template-rows: auto auto;
    place-items: center;
  }

  #audit-rgpd-answers-and-comments-container {
    width: 99%;
  }
}
