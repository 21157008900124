@import "./globalStyles/variables";
@import "./globalStyles/animations";
@import "./globalStyles/buttons&badges";
@import "./globalStyles/containers";
@import "./globalStyles/cursors";
@import "./globalStyles/images";
@import "./globalStyles/resetStyles";
@import "./globalStyles/texts";

@import "./pagesStyles/toolsLayout";
@import "./pagesStyles/homepage";
@import "./pagesStyles/pleaseConnect";
@import "./pagesStyles/toolDescription";
@import "./pagesStyles/signInUp";
@import "./pagesStyles/signInUpErrorMessages.scss";
@import "./pagesStyles/resetPassword";
@import "./pagesStyles/navbar";
@import "./pagesStyles/userInformations";
@import "./pagesStyles/userInformationsEditionFormErrorMessages";
@import "./pagesStyles/dashboard";
@import "./pagesStyles/dashboardPreaudit";
@import "./pagesStyles/dashboardSupplierAssesment";
@import "./pagesStyles/supplierAssesmentAnswering";
@import "./pagesStyles/supplierInformationsEditionFormErrorMessages";
@import "./pagesStyles/dashboardAuditRGPD";
@import "./pagesStyles/nis2";
@import "./pagesStyles/footer";
@import "./pagesStyles/cgu";
@import "./pagesStyles/privacyPolicy";
@import "./pagesStyles/legalMentions";

body {
  background-color: $dark-blue1-full-opacity;
}

.page-title-container {
  // border: 1px solid purple;
  border-bottom: 1px solid $orange-full-opacity;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}

.page-title-icon-and-text {
  // border: 1px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;

  // margin-right: auto;
}

.page-title-icon {
  // border: 1px solid orange;
  background-color: $orange-full-opacity;
  border-radius: 50%;
  padding: 7.5px;
  font-size: 1.9rem;
  display: flex;
  align-self: center;
  justify-self: center;
}

.page-title-text {
  // border: 1px solid greenyellow;
  font-family: Designosaur, sans-serif;
  font-size: 2rem;
  text-align: center;
  margin-left: 5px;
}

li {
  color: $dark-blue1-full-opacity;
}

.test {
  border: 1px solid red;
}

@media all and (max-width: 800px) {
  .page-title-container {
    flex-direction: column;
    place-items: center;
    margin-top: 25px;
  }

  .page-title-icon-and-text {
    margin-right: auto;
  }
}

.display-none {
  display: none;
}
