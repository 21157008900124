#signinup-container {
  // border: 1px solid orange;
  width: auto;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
}

#title-container {
  // border: 1px solid red;
  // width: 100%;
  // min-height: 100vh;
  padding-top: 25px;
}

#image3-container {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

#connnexion-container {
  // border: 10px solid green;
  background-color: $white-full-opacity;
  // width: 100%;
  min-height: 100vh;
  padding-top: 25px;
}

#tool-title-container {
  // border: 1px solid purple;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  margin-top: 50px;
}

#image4-container {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.signinup-text-input {
  color: $dark-blue1-full-opacity;
  width: 100%;
  background-color: none;
  border-bottom: 1px solid $dark-blue1-full-opacity;
}

#signin-form-container {
  // border: 1px solid red;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 50px;
}

.user-name-container {
  // border: 1px solid blue;
  height: 50px;
  line-height: 50px;
  margin-top: 20px;
}

.password-container {
  // border: 1px solid blue;
  height: 50px;
  line-height: 50px;
}

#signin-link-container {
  // border: 1px solid red;
  height: 25px;
  line-height: 25px;
  text-align: end;
  font-size: 0.75rem;
  text-decoration: underline;
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
}

.forgotten-password-container {
  // border: 1px solid red;
  height: 25px;
  line-height: 25px;
  text-align: end;
  font-size: 0.75rem;
  text-decoration: underline;
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
}

#signup-form-container {
  // border: 1px solid red;
  width: 50%;
  // min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

#captcha-container {
  // border: 1px solid blue;
  height: 50px;
  line-height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
}

#cgu-validation-container {
  // border: 1px solid blue;
  height: 50px;
  line-height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
}

#signup-link-container {
  // border: 1px solid red;
  height: 25px;
  line-height: 25px;
  text-align: end;
  font-size: 0.75rem;
  text-decoration: underline;
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
}

#weak-password-container {
  // border: 1px solid red;
  height: 25px;
  line-height: 25px;
  text-align: end;
  font-size: 0.75rem;
  text-decoration: underline;
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
}

.submit-button-container {
  // border: 1px solid red;
  background-color: $light-blue1-full-opacity;
  border-radius: 5px;
  text-align: center;
  color: $white-full-opacity;
  cursor: pointer;
  margin-top: 50px;
  font-size: 1.5rem;
}

.submit-button-container:hover {
  // border: 1px solid red;
  background-color: $light-blue1-half-opacity;
  // border-radius: 5px;
  // text-align: center;
  // color: $white-full-opacity;
  // cursor: pointer;
  // margin-top: 50px;
  // font-size: 1.5rem;
}

.submit-button {
  width: 100%;
  font-family: Designosaur, sans-serif;
}

@media all and (max-width: 1230px) {
  #signin-form-container {
    // border: 1px solid red;
    width: 75%;
  }

  #signup-form-container {
    // border: 1px solid red;
    width: 90%;
  }
}

@media all and (max-width: 900px) {
  #signin-form-container {
    // border: 1px solid red;
    width: 90%;
  }

  #signup-form-container {
    // border: 1px solid red;
    width: 75%;
  }
}

@media all and (max-width: 800px) {
  #signinup-container {
    // border: 1px solid orange;
    // width: 99vw;
    // height: 99vh;
    // display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    // align-items: flex-start;
  }
}
