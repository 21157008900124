@font-face {
  font-family: "Nunito-VariableFont";
  src: url("../../../fonts/Nunito-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Designosaur";
  src: url("../../../fonts/Designosaur.ttf.woff") format("truetype");
}

@font-face {
  font-family: "Plus Jakarta Sans Italic";
  src: url("../../../fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Plus Jakarta Sans Variable";
  src: url("../../../fonts/PlusJakartaSans-VariableFont_wght.ttf")
    format("truetype");
}

//////// FONT FAMILIES ////////
.font-family-jakarta-sansvariable {
  font-family: Plus Jakarta Sans Variable, sans-serif;
}

.font-family-designosaur {
  font-family: Designosaur, sans-serif;
}

.font-family-nunito-variablefont {
  font-family: Nunito-VariableFont, sans-serif;
}

//////// FONT SIZES ////////
.font-size-060rem {
  font-size: 0.6rem;
}

.font-size-065rem {
  font-size: 0.65rem;
}

.font-size-070rem {
  font-size: 0.7rem;
}

.font-size-075rem {
  font-size: 0.75rem;
}

.font-size-080rem {
  font-size: 0.8rem;
}

.font-size-085rem {
  font-size: 0.85rem;
}

.font-size-090rem {
  font-size: 0.9rem;
}

.font-size-095rem {
  font-size: 0.95rem;
}

.font-size-1-25rem {
  font-size: 1.25rem;
}

.font-size-1-50rem {
  font-size: 1.5rem;
}

.font-size-1-75rem {
  font-size: 1.75rem;
}

.font-size-2rem {
  font-size: 2rem;
}

//////// FONT WEIGHTS ////////
.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-700 {
  font-weight: 700;
}

//////// FONT COLORS ////////
.font-white {
  color: $white-full-opacity;
}

.font-red {
  color: $red-full-opacity;
}

.font-green {
  color: $green-full-opacity;
}

.font-orange {
  color: $orange-full-opacity;
}

.font-dark-blue1 {
  color: $dark-blue1-full-opacity;
}

.font-dark-blue2 {
  color: $dark-blue2-full-opacity;
}

.font-dark-blue3 {
  color: $dark-blue3-full-opacity;
}

.font-light-blue2 {
  color: $light-blue2-full-opacity;
}

.font-light-blue3 {
  color: $light-blue3-full-opacity;
}

//////// FONT STYLES ////////
.font-style-italic {
  font-style: italic;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

//////// TEXTS ALIGNEMENTS ////////
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}
