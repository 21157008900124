.user-informations-container {
  //   border: 5px solid orange;
  width: auto;
  min-height: 100vh;
  padding-left: 10px;
  padding-right: 10px;
}

.user-informations-request-container {
  // border: 1px solid red;
  // position:fixed;
  // top:30px;
  // left:41.5vw;
  width: 445px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
    // font-family: Designosaur, sans-serif;
  }
}

#user-informations-buttons-container {
  //   border: 1px solid burlywood;
  width: 445px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Utilisation de space-between */
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.user-informations-button {
  //   border: 1px solid green;
  width: 150px;
  background-color: $light-blue2-full-opacity;
  border-radius: 5px;
  padding: 5px;
  font-family: Designosaur, sans-serif;
  text-align: center;
  cursor: pointer;
  //   margin-right: 10px;
  font-size: 1rem;
}

.user-informations-button-disabled {
  //   border: 1px solid green;
  width: 150px;
  background-color: $light-blue2-half-opacity;
  border-radius: 5px;
  padding: 5px;
  font-family: Designosaur, sans-serif;
  text-align: center;
  // cursor: pointer;
  //   margin-right: 10px;
  font-size: 1rem;
}

.user-informations-button:hover {
  background-color: $light-blue2-half-opacity;
}

.all-informations-container {
  // border: 1px solid pink;
  background-color: $white-full-opacity;
  width: 425px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: 50px;
  font-size: 0.9rem;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
}

.single-information-container {
  // border: 1px solid orange;
  //   border-bottom: 1px solid $dark-blue1-full-opacity;
  width: 100%;
  height: 40px;
  line-height: 40px;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: left;
  align-items: flex-start;
  //   margin-bottom: 30px;
}

.information-label {
  //   border-bottom: 1px solid $dark-blue1-full-opacity;
  width: 100%;
  font-size: 0.9rem;
  font-weight: bold;
  color: $dark-blue1-full-opacity;
}

.information-text {
  width: 100%;
  padding-left: 5px;
  color: $dark-blue1-full-opacity;
  //   border-bottom: 1px solid $dark-blue1-full-opacity;
}

#tooltip-container {
  border: 5px solid $light-blue2-full-opacity;
  background-color: $white-full-opacity;
  position: fixed;
  top: 50%;
  right: 20px;
  width: 350px;
  height: fit-content;
  padding: 10px;
  border-radius: 10px;
  // font-family: Designosaur, sans-serif;
  font-weight: light;
  text-align: justify;
  font-size: 0.9rem;
  //   font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
  z-index: +1;
}

#user-informations-validation-button {
  //   border: 1px solid green;
  width: 150px;
  border-radius: 5px;
  padding: 5px;
  font-family: Designosaur, sans-serif;
  text-align: center;
  cursor: pointer;
  //   margin-right: 10px;
  font-size: 1rem;
  background-color: $green-full-opacity;
  color: $white-full-opacity;
}

#user-informations-validation-button:hover {
  background-color: $green-half-opacity;
}

#user-informations-cancel-button {
  //   border: 1px solid green;
  width: 150px;
  border-radius: 5px;
  padding: 5px;
  font-family: Designosaur, sans-serif;
  text-align: center;
  cursor: pointer;
  //   margin-right: 10px;
  font-size: 1rem;
  background-color: $red-full-opacity;
  color: $white-full-opacity;
}

#user-informations-cancel-button:hover {
  background-color: $red-half-opacity;
}

.information-input {
  // background-color: $black-half-opacity;
  color: $dark-blue1-full-opacity;
  border-bottom: 1px solid $dark-blue1-full-opacity;
}

#user-informations-illustration-container {
  // border: 1 px solid red;
  position: absolute;
  top: 200px;
  right: 275px;
  // z-index: -100;
}

.user-informations-illustration {
  width: 100px;
}

#user-informations-edition-illustration-container {
  // border: 1px solid red;
  position: absolute;
  top: 420px;
  right: 250px;
}

.user-informations-edition-illustration {
  width: 100px;
}
