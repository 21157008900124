#please-connect-container {
    // border: 5px solid green;
    // background-color: $white-full-opacity;
    width: 100%;
    height: 100vh;
  }
  
  #please-connect-text {
    // border: 1px solid blue;
    background-color: $dark-blue1-full-opacity;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    color: $white-full-opacity;
    // font-weight: bold;
    font-size: 1.5rem;
    font-family: Designosaur, sans-serif;
  }
  
  #tool-selection-container{
    // border: 1px solid pink;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    padding: 20px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
  
  .tool-button{
    // border: 1px solid orange;
    width: 250px;
    height: 50px;
    text-align: center;
    border-radius: 10px;
    background-color: $light-blue1-full-opacity;
    color: $white-full-opacity;
    font-family: Designosaur, sans-serif;
    font-weight: bold;
    cursor: pointer;
  }
  
  .tool-button:hover{
    // border: 1px solid orange;
  
    background-color: $light-blue3-full-opacity;
    color: $white-full-opacity;
  
  
  }
  
  #logo-and-name-container-in-please-connect {
    // border: 1px solid yellow;
    width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 250px;
  }
  
  #logo-container-in-please-connect {
    // border: 1px solid green;
    width: 150px;
    // height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  #act-logo-in-please-connect {
    // width: 100px;
    // justify-self: right;
  }
  
  #act-name-in-please-connect {
    padding-left: 10px;
    text-align: center;
  }
  