// Styles for SupplierAssesmentAnswering MENU //
#back-to-top-container {
  // border: 1px solid red;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin-right: 20px;
  margin-bottom: 20px;
}

#supplier-assesment-answering-menu-container {
  // border: 1px solid red;
}

#supplier-assesment-save-button-container {
  //   border: 1px solid violet;
  width: fit-content;
  border: 3px solid $red-full-opacity;
  background-color: $white-full-opacity;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  cursor: pointer;
}

#supplier-assesment-save-button {
  display: flex;
  align-self: center;
  padding: 7.5px;
  font-size: 1.5rem;
  transition: font-size 0.25s ease;
}

#supplier-assesment-save-button:hover {
  font-size: 2.5rem;
}

.supplier-assesment-themes-button-container {
  width: fit-content;
  background-color: $orange-full-opacity;
  // border: 3px solid $red-full-opacity;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  cursor: pointer;
}

.supplier-assesment-themes-button {
  display: flex;
  align-self: center;
  padding: 7.5px;
  font-size: 1.5rem;
  transition: font-size 0.25s ease;
}

.supplier-assesment-themes-button:hover {
  font-size: 2.5rem;
}

#supplier-assesment-themes-container {
  border: 1px solid $orange-half-opacity;
  // border-top: 3px solid $orange-full-opacity;
  background-color: $dark-blue2-full-opacity;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  // align-content: center;
  // justify-items: center;
  // width: 100%;
}

.supplier-assesment-single-theme-container {
  border-bottom: 1px solid $orange-half-opacity;
  font-size: 0.8rem;
  width: auto;
  height: auto;
  // margin-top: 10px;
  padding: 10px;
  // padding-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.25s;
}

.supplier-assesment-single-theme-container:hover {
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
  // font-weight: bold;
  // text-align: center;
}

// Styles for SupplierAssesmentAnswering MENU END //

// Styles for  Supplier Assesment Edition //
#supplier-assesment-answering-container {
  //   border: 5px solid orange;
  width: auto;
  min-height: 100vh;
  padding-left: 10px;
  padding-right: 10px;
}
// Styles for  Supplier Assesment Edition END //

// Styles for Supplier Assesment Questions //
#supplier-assesment-answering-container {
  //   border: 5px solid orange;
  width: auto;
  min-height: 100vh;
  padding-left: 10px;
  padding-right: 10px;
}

.supplier-assesment-theme-container {
  text-align: center;
  padding: 30px;
}
// Styles for Supplier Assesment Questions END //

// Styles for Supplier Information Edition //
#supplier-information-description {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.menu-icon {
  border-radius: 50%;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

// Styles for Supplier Information Edition END //

// Styles for Supplier Information Edition Form //
#supplier-informations-edition-form {
  //   border: 1px solid red;
}

.supplier-all-informations-container {
  // border: 1px solid pink;
  background-color: $white-full-opacity;
  width: 425px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: 50px;
  font-size: 0.9rem;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
}

.supplier-single-information-container {
  // border: 1px solid orange;
  //   border-bottom: 1px solid $dark-blue1-full-opacity;
  width: 100%;
  height: 40px;
  line-height: 40px;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: left;
  align-items: flex-start;
  //   margin-bottom: 30px;
}

.supplier-information-label {
  //   border-bottom: 1px solid $dark-blue1-full-opacity;
  width: 100%;
  font-size: 0.9rem;
  font-weight: bold;
  color: $dark-blue1-full-opacity;
}

.supplier-information-text {
  width: 100%;
  padding-left: 5px;
  color: $dark-blue1-full-opacity;
  //   border-bottom: 1px solid $dark-blue1-full-opacity;
}

.supplier-information-input {
  // background-color: $black-half-opacity;
  color: $dark-blue1-full-opacity;
  border-bottom: 1px solid $dark-blue1-full-opacity;
}

#supplier-informations-submit-container {
  //   border: 1px solid green;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

#supplier-informations-submit-button {
  //   border: 1px solid green;
  width: 150px;
  background-color: $green-full-opacity;
  border-radius: 5px;
  padding: 5px;
  font-family: Designosaur, sans-serif;
  text-align: center;
  cursor: pointer;
  //   margin-right: 10px;
  font-size: 1rem;
}

#supplier-informations-submit-button:hover {
  background-color: $green-half-opacity;
}
// Styles for Supplier Information Edition Form END //

// Styles for SupplierAssesmentQuestionsTemplate //
.supplier-assesment-single-question-container {
  // border: 5px solid orange;
  background-color: $white-full-opacity;
  width: 600px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  padding-bottom: 10px;
}

.supplier-assesment-single-question-header {
  // border: 5px solid yellowgreen;
  background-color: $light-blue3-full-opacity;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.supplier-assesment-question-id-container {
  color: $white-full-opacity;
  // padding: 5px;
  font-size: 1.25rem;
  font-family: Designosaur, sans-serif;
}

.supplier-assesment-question-content-container {
  // background-color: red;
  color: $dark-blue1-full-opacity;
  font-family: Designosaur, sans-serif;
  font-size: 0.9rem;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 2%;
  text-align: justify;
}

.supplier-assesment-answer-container {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  // border: 1px solid white;
}

.supplier-assesment-input-container {
  // border: 1px solid red;
  // border: 1px solid violet;
}

.supplier-assesment-input-label {
  // border: 1px solid red;
  cursor: pointer;
  color: $dark-blue1-full-opacity;
  padding-left: 5px;
  font-family: Designosaur, sans-serif;
}

.supplier-assesment-comment-container {
  // border: 1px solid red;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 5px;
}
.supplier-assesment-comment-label {
  // border: 1px solid blue;
  color: $dark-blue1-full-opacity;
  font-family: Designosaur, sans-serif;
}
.supplier-assesment-comment-input {
  // border: 1px solid blue;
  width: 96%;
}
// Styles for SupplierAssesmentQuestionsTemplate END //
