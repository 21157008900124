// Styles for Supplier Assement Dashboard //
#supplier-assesment-dashboard-container {
  // border: 1px solid salmon;

  margin-top: 50px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;

  h1 {
    font-family: Designosaur, sans-serif;
    font-size: 1.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    // align-content: center;
  }
}

#suppliers-list-and-dashboard-buttons-container {
  // border: 1px solid yellowgreen;
  display: grid;
  grid-template-columns: 250px auto;
  margin-top: 50px;
}

#supplier-assesment-dashboard-buttons-container {
  // border: 1px solid purple;
}

.supplier-assesment-dashboard-button-container {
  // border: 1px solid red;
  // display: flex;
  // align-content: center;
}

#supplier-assesment-suppliers-list-button {
  // background-color: $light-blue2-full-opacity;
}

#supplier-assesment-questions-list-button {
  // background-color: $light-blue2-full-opacity;
}

.supplier-assesment-dashboard-button {
  // border: 1px solid blue;
  // background-color: $white;
  width: 150px;
  height: 50px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-family: Designosaur, sans-serif;
  margin-bottom: 10px;
}

#supplier-assesment-suppliers-list-button {
  background-color: $green-full-opacity;
}

#supplier-assesment-suppliers-list-button:hover {
  // border: 1px solid yellowgreen;
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
}

#supplier-assesment-questions-list-button {
  background-color: $light-blue3-full-opacity;
}

#supplier-assesment-questions-list-button:hover {
  // border: 1px solid yellowgreen;
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
}

// Styles for Supplier Assement Dashboard END//

// Styles for Suppliers Listing //
#suppliers-listing-title {
  // border: 1px solid red;
}

#suppliers-listing-container {
  // border: 1px solid red;
  // background-color: $dark-blue1-full-opacity;
  margin-top: 15px;
}
// Styles for Suppliers Listing END //

// Styles for Suppliers Summary  //
.supplier-summary-container {
  // border: 1px solid orange;
  border-top: 1px solid $white-half-opacity;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  // cursor: pointer;
}

.supplier-is-selected {
  background-color: $light-blue2-full-opacity;
}

// .supplier-summary-container:hover {
//   background-color: $dark-blue1-full-opacity;
//   // border: 1px solid $white-half-opacity;
// }

.supplier-container {
  // border: 1px solid red;
  width: 33.3%;
  // font-family: Designosaur, sans-serif;
  font-size: 0.9rem;
  align-self: center;
  cursor: pointer;
}

// .realize-assesment-button-container {
//   // border: 1px solid red;

//   width: 33.3%;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
// }

// .assesments-listing-button-container {
// border: 1px solid red;

// width: 33.3%;
// display: flex;
// flex-direction: row;
// justify-content: center;
// }

.suppliers-listing-button-container {
  // border: 1px solid red;
  width: 33.3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // align-items: center;
}

.suppliers-listing-button {
  // border: 1px solid orange;
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  font-family: Designosaur, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 150px;
}

.suppliers-listing-button:hover {
  background-color: $white-half-opacity;
}
// Styles for Suppliers Summary END //

//////////////////////////////////
// Styles for Add supplier form //
#add-supplier-form-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#add-supplier-form {
  // border: 1px solid red;
  background-color: $white-full-opacity;
  border-radius: 10px;
  width: 400px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  text-align: center;
}

.add-supplier-form-label-container {
  // border: 1px solid blue;
}

.add-supplier-form-label {
  // border: 1px solid blue;
  height: 50px;
  line-height: 50px;
  color: $dark-blue1-full-opacity;
  text-align: start;
}

.add-supplier-form-input-container {
  // border: 1px solid red;
  height: 50px;
  line-height: 50px;
}

.add-supplier-form-input {
  color: $dark-blue1-full-opacity;
  width: 100%;
  background-color: none;
  border-bottom: 1px solid $dark-blue1-full-opacity;
}

#add-supplier-form-submit-buttons-container {
  // border: 1px solid blue;
  display: flex;
  justify-content: space-around;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

#add-supplier-form-submit-button {
  // border: 1px solid purple;
  background-color: $light-blue1-full-opacity;
  color: $white-full-opacity;
  width: 75px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  // font-weight: bold;
  font-family: Designosaur, sans-serif;
}

#add-supplier-form-submit-button:hover {
  // border: 1px solid purple;
  background-color: $light-blue1-half-opacity;
  color: $white-full-opacity;
}

#add-supplier-form-cancel-button {
  // border: 1px solid purple;
  background-color: $red-full-opacity;
  color: $white-full-opacity;
  width: 75px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  // font-weight: bold;
  font-family: Designosaur, sans-serif;
}

#add-supplier-form-cancel-button:hover {
  // border: 1px solid purple;
  background-color: $red-half-opacity;
  color: $white-full-opacity;
}

// Styles for Add supplier form END //

// Styles for Supplier Assesment Questions List //
#supplier-assesment-questions-list-modal {
  // border: 1px solid red;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#supplier-assesment-questions-list-container {
  // border: 1px solid $white-full-opacity;
  border-left: 1px solid $white-half-opacity;
  background-color: $dark-blue1-full-opacity;
  color: $white-full-opacity;
  margin-left: auto;
  // margin-right: auto;
  // margin-top: 10vh;
  width: 50%;
  min-height: 100vh;
  // border-radius: 10px;
  padding: 5px;
  // overflow-y: auto;
}

#supplier-assesment-questions-list-title-container {
  h2 {
    font-family: Designosaur, sans-serif;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.25rem;
  }
}

#supplier-assesment-questions-list {
  // border: 1px solid blue;
  display: grid;
  grid-template-columns: 1fr;
  max-height: 85vh;
  overflow-y: auto;
}

.supplier-assesment-question-container {
  // border: 1px solid red;
  // border-bottom: 1px solid $white-half-opacity;
  margin-bottom: 20px;
  padding: 5px;
}

.supplier-assesment-question-title {
  font-family: Designosaur, sans-serif;
}

.supplier-assesment-question-content {
  font-size: 0.9rem;
}

.even-line {
  background-color: $dark-blue1-full-opacity;
  color: $white-full-opacity;
}

.odd-line {
  background-color: $white-half-opacity;
  color: $white-full-opacity;
}
// Styles for Supplier Assesment Questions List END //

// Styles for Supplier Details //
#supplier-details-container {
  // border: 1px solid red;
  background-color: $dark-blue2-full-opacity;
  border-bottom: 1px solid $white-half-opacity;
  padding: 20px;

  ul {
    // padding-left: 5px;

    li {
      font-size: 0.85rem;
      margin-bottom: 3px;
      list-style: circle;
      color: $white-full-opacity;
      // font-family: Designosaur, sans-serif;
    }
  }
}

// Styles for Supplier Details END //

// Styles for Supplier Assesment Listing //
#supplier-assesments-listing-container {
  // border: 1px solid red;
  border-bottom: 1px solid $white-half-opacity;

  background-color: $dark-blue2-full-opacity;
  padding: 20px;
  // padding-top: 20px;

  ul {
    // padding-left: 5px;

    li {
      font-size: 0.85rem;
      margin-bottom: 3px;
      list-style: circle;
      color: $white-full-opacity;
      cursor: pointer;
    }

    li:hover {
      font-weight: bold;
    }
  }
}

#supplier-assesments-listing {
  // border: 1px solid red;
}
// Styles for Supplier Assesment Listing END //

// Styles for Supplier Assesment Charts //
#supplier-assesment-charts-modal {
  // border: 1px solid red;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
  overflow-y: scroll;
}

#supplier-assesment-title-container {
  background-color: $dark-blue1-full-opacity;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#supplier-assesment-charts-container {
  // border: 3px solid orange;
  // background-color: $white-full-opacity;
  width: 99%;
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: 50px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.single-supplier-assesment-chart-container {
  border: 2px solid $white-full-opacity;
  background-color: $dark-blue3-full-opacity;
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 50px;
  cursor: pointer;
}

.single-supplier-assesment-chart-container:hover {
  border: 2px solid $orange-full-opacity;

  background-color: $dark-blue2-full-opacity;
}

.supplier-assesment-chart-title-container {
  // border: 3px solid rgb(0, 255, 72);
  background-color: $white-full-opacity;
  border-radius: 10px 10px 0 0;
  // width: 100%;
  height: auto;
  // line-height: 50px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 5px;
}

.supplier-assesment-title-header {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.supplier-assesment-category-container {
  // border: 1px solid blue;

  color: $dark-blue1-full-opacity;
  text-align: center;
  font-family: Designosaur, sans-serif;
}

.supplier-assesment-total-questions-container {
  // border: 1px solid black;
  display: grid;
  grid-template-columns: auto auto;
  place-items: center;
  width: fit-content;
}

.supplier-assesment-total-questions {
  // border: 1px solid orange;
  // width: fit-content;
  // position: absolute;
  font-size: 0.8rem;
  color: $dark-blue1-full-opacity;
}

.supplier-assesment-completion-icon-container {
  // position: relative;
  // right: -30px;
  padding-left: 5px;
}

.answers-quantities-container {
  // border: 1px solid red;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

.answers-quantity-yes {
  background-color: $green-full-opacity;
  font-family: Designosaur, sans-serif;
  color: $white-full-opacity;
  border-radius: 10px;
  padding: 3px;
}

.answers-quantity-no {
  background-color: $red-full-opacity;
  font-family: Designosaur, sans-serif;
  color: $white-full-opacity;
  border-radius: 10px;
  padding: 3px;
}

.answers-quantity-na {
  background-color: $white-full-opacity;
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
  border-radius: 10px;
  padding: 3px;
}

.supplier-assesment-chart-container {
  // border: 3px solid rgb(0, 234, 255);
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  // cursor: pointer;
}

// Styles for Supplier Assesment Charts END //

// Styles for Supplier Assesment Answers and Comments //
#supplier-assesment-answers-and-comments-modal {
  // border: 1px solid red;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#supplier-assesment-answers-and-comments-container {
  // border: 1px solid $white-full-opacity;
  border-left: 1px solid $white-half-opacity;
  background-color: $dark-blue1-full-opacity;
  color: $white-full-opacity;
  margin-left: auto;
  // margin-right: auto;
  // margin-top: 10vh;
  width: 50%;
  min-height: 100vh;
  // border-radius: 10px;
  padding: 2px;
  // overflow-y: auto;
}

#supplier-assesment-answers-and-comments-title-container {
  h2 {
    font-family: Designosaur, sans-serif;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.25rem;
  }
}

#supplier-assesment-answers-and-comments {
  // border: 1px solid blue;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
}

.supplier-assesment-single-answer-and-comment-container {
  background-color: $white-full-opacity;
  margin-bottom: 30px;
}

.supplier-assesment-question-container {
  // border: 1px solid red;
  margin-bottom: 0px;
  color: $dark-blue1-full-opacity;
}

.supplier-assesment-answer-container {
  // border: 1px solid red;
  // margin-bottom: 25px;
}

.not-yet-answered {
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
  font-style: italic;
}

.answered {
  font-family: Designosaur, sans-serif;
  color: $dark-blue1-full-opacity;
  // padding: 3px;
}

.answer-yes {
  border-left: 10px solid $green-full-opacity;
  padding-left: 5px;
}

.answer-no {
  border-left: 10px solid $red-full-opacity;
  padding-left: 5px;
}

.answer-na {
  border-left: 10px solid $dark-blue1-full-opacity;
  padding-left: 5px;
}
// Styles for Supplier Assesment Answers and Comments END //

@media all and (max-width: 1300px) {
  #supplier-assesment-charts-container {
    grid-template-columns: repeat(2, auto);
  }
}

@media all and (max-width: 1000px) {
  #supplier-assesment-answers-and-comments-container {
    width: 75%;
  }
}

@media all and (max-width: 950px) {
  #suppliers-list-and-dashboard-buttons-container {
    // border: 1px solid yellowgreen;
    // display: grid;
    grid-template-columns: 1fr;
    // justify-items: center;
  }

  #supplier-assesment-dashboard-buttons-container {
    // border: 1px solid purple;
    justify-self: center;
    margin-bottom: 50px;
  }
}

@media all and (max-width: 900px) {
  #supplier-assesment-charts-container {
    grid-template-columns: repeat(1, auto);
  }
}

@media all and (max-width: 610px) {
  #supplier-assesment-answers-and-comments-container {
    width: 99%;
  }
}
