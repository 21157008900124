#supplier-informations-edition-form-error-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#supplier-informations-edition-form-error-container {
  // border: 1px solid red;
  background-color: $white-full-opacity;
  border-radius: 10px;
  width: 400px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  text-align: center;
}

#supplier-informations-edition-form-error{
  color: $dark-blue1-full-opacity;
}

#supplier-informations-edition-form-error-close-button-container {
  // border: 1px solid purple;
  background-color: $red-full-opacity;
  color: $white-full-opacity;
  width: 75px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

#supplier-informations-edition-form-error-close-button-container:hover {
  // border: 1px solid purple;
  background-color: $red-half-opacity;
}

#supplier-informations-edition-form-error-close-button {
  font-family: Designosaur, sans-serif;
}

@media all and (max-width: 500px) {
  #supplier-informations-edition-form-error-container {
    // border: 1px solid red;
    background-color: $white-full-opacity;

    width: 250px;
    margin-top: 150px;

    padding: 25px;
  }
}
