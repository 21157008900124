////////// STYLES FOR NIS 2 FORM WELCOME //////////

#nis2-page-container {
  min-height: 100vh;
}

.nis2-form-container {
  // border: 1px solid orange;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#nis2-form-title-container {
  // border: 1px solid red;
  width: 100vw;
  height: 125px;
  background-image: url("../../../images/nis2-form-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white-full-opacity;
  text-align: center;
}

#nis2-form-title-container > h1 {
  font-family: Plus Jakarta Sans Variable, sans-serif;
  font-weight: 700;
}

#nis2-form-subtitle-container {
  background-color: $light-blue1-full-opacity;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  position: relative;
}

.woman-sitting-with-computer-for-nis2-form {
  // border: 1px solid red;
  background-image: url("../../../images/woman-sitting-with-computer.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -82px;
  right: 45px;
  width: 110px;
  height: 110px;
}

.two-men-climbing-for-nis2-form {
  // border: 1px solid red;
  background-image: url("../../../images/two-men-climbing.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -47px;
  left: 35px;
  width: 150px;
  height: 150px;
}

#nis2-form-subtitle-container > h4 {
  font-family: Plus Jakarta Sans Variable, sans-serif;
  text-align: center;
  font-weight: 400;
}

#nis2-form-image-title-container {
  // border: 1px red;
  // background-color: red;
  min-width: 360px;
  text-align: center;
}

.nis2-form-image-title {
  width: 550px;
  min-width: 360px;
}

#nis2-form-catchphrase-container {
  // border: 1px solid blue;
  width: 50vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

#nis2-form-start-button-container {
  // border: 1px solid red;
  position: relative;
  background-color: $dark-blue4-full-opacity;
  width: 200px;
  height: auto;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.green-arrow-for-nis2-form {
  // border: 1px solid red;
  background-image: url("../../../images/green-arrow.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 25px;
  right: -25px;
  width: 35px;
  height: 35px;
}

#nis2-form-start-button-container:hover {
  background-color: $light-blue1-full-opacity;
  cursor: pointer;
}

#nis2-form-start-button {
  text-align: center;
  font-family: Designosaur, sans-serif;
}
////////// STYLES FOR NIS 2 FORM WELCOME END //////////

////////// STYLES FOR NIS 2 FORM QUESTIONS //////////

.nis2form-question-container {
  border-left: 5px solid $light-blue2-full-opacity;
  margin-top: 75px;
  margin-bottom: 25px;
  padding: 10px;
}

.nis2form-question-content {
  // border: 1px solid red;
  background-color: $light-blue2-full-opacity;
  padding: 10px;
}

.nis2form-answers-container {
  background-color: $dark-blue3-full-opacity;
  padding-top: 10px;
}

.nis2-form-answers {
  font-family: Designosaur, sans-serif;
  margin-right: 25px;
}

.nis2-form-answers:hover {
  cursor: pointer;
}

.nis2-form-sub-answers {
  margin-right: 25px;
  font-size: 0.85em;
}

.nis2-form-sub-answers:hover {
  cursor: pointer;
}

.nis2-form-answers-input {
  margin-right: 5px;
}

.nis2-validation-button-container {
  // border: 1px solid red;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  padding: 5px 10px 5px 10px;
  background-color: $dark-blue4-full-opacity;
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.nis2-validation-button-container:hover {
  background-color: $light-blue1-full-opacity;
  cursor: pointer;
}

.nis2-validation-button-text {
  color: $white-full-opacity;
  font-family: Plus Jakarta Sans Variable, sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
}

.nis2-validation-button-arrow {
  color: $white-full-opacity;
  font-size: 2rem;
  padding-left: 10px;
}

////////// STYLES FOR NIS 2 FORM QUESTIONS END //////////

////////// STYLES FOR NIS 2 FORM QUESTIONS ON SECTORS OF ACTIVITY //////////

#q2-sectors-of-activity-container {
  // border: 1px solid red;
  background-color: $dark-blue3-full-opacity;

  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.nis2-single-sector-container {
  margin-top: 10px;
  padding: 10px;
}

.nis2-sub-sectors-container {
  border-left: 1px solid $white-full-opacity;
  padding: 10px;
  display: grid;
  grid-template-columns: auto;
}

////////// STYLES FOR NIS 2 FORM QUESTIONS ON SECTORS OF ACTIVITY END //////////

////////// STYLES FOR NIS 2 POPUPS //////////

#nis-pop-up-modal {
  // border: 1px solid orange;
  background-color: $black-half-opacity;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#nis2-pop-up-container {
  // border: 1px solid red;
  background-color: $white-full-opacity;
  margin-top: 10vh;
  width: fit-content;
  padding: 20px;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 5px $white-full-opacity;
}

.nis2-pop-up-content {
  // border: 1px solid red;
  color: $dark-blue1-full-opacity;
  text-align: center;
}

.nis2-pop-up-button-container {
  // border: 1px solid red;
  width: fit-content;
  height: fit-content;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  padding: 2px 10px 2px 10px;
  background-color: $dark-blue1-full-opacity;
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.nis2-pop-up-button-container:hover {
  background-color: $light-blue1-full-opacity;
  cursor: pointer;
}

.nis2-pop-up-button-text {
  color: $white-full-opacity;
  font-family: Plus Jakarta Sans Variable, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.nis2-pop-up-button-arrow {
  color: $white-full-opacity;
  font-size: 1.75rem;
  padding-left: 10px;
}

////////// STYLES FOR NIS 2 POPUPS END //////////

////////// STYLES FOR NIS 2 SUBMIT ANSWERS FORM //////////

#nis2-submit-form-modal {
  // border: 1px solid orange;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: +1;
}

#nis2-submit-form {
  // border: 1px solid red;
  background-color: $white-full-opacity;
  border-radius: 1vh;
  width: 50vw;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  padding: 5vh;
  text-align: center;
}

#nis2-submit-form-fields-container {
  // border: 1px solid red;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 55% 45%;
  text-align: left;
}

.nis2-submit-form-label-container {
  // border: 1px solid blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  padding: 3px;
}

.nis2-submit-form-label {
  color: $dark-blue1-full-opacity;
  padding-left: 5px;
}

.nis2-submit-form-input-container {
  // border: 1px solid blue;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}

.nis2-submit-form-input {
  color: $dark-blue1-full-opacity;
  background-color: $light-blue1-half-opacity;
}

.nis2-submit-form-button-container {
  //   border: 1px solid red;
  width: fit-content;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  background-color: $light-blue1-full-opacity;
  border: 3px solid $light-blue1-full-opacity;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.nis2-submit-form-button-container:hover {
  background-color: $light-blue1-half-opacity;
  border: 3px solid $light-blue1-full-opacity;
  cursor: pointer;
}

.nis2-submit-form-button {
  color: $white-full-opacity;
  font-family: Designosaur, sans-serif;
  padding: 20px;
}

.nis2-submit-form-button:hover {
  color: $dark-blue1-full-opacity;
}

////////// STYLES FOR NIS 2 SUBMIT ANSWERS FORM END //////////

////////// STYLES FOR NIS 2 CGU //////////

#nis2-cgu-container {
  margin-top: 25px;
}

#nis2-cgu-title {
  font-size: 0.8rem;
  font-weight: bold;
  color: $dark-blue1-full-opacity;
  text-align: justify;
}

.nis2-cgu-content {
  font-size: 0.6rem;
  color: $dark-blue1-full-opacity;
  text-align: justify;
}

.cgu-validation {
  // cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
}

////////// STYLES FOR NIS 2 CGU END //////////

@media all and (max-width: 1150px) {
  .woman-sitting-with-computer-for-nis2-form {
    display: none;
  }

  .two-men-climbing-for-nis2-form {
    display: none;
  }
}

@media all and (max-width: 1130px) {
  #nis2-submit-form-fields-container {
    // border: 1px solid red;
    width: 100%;
  }
}

@media all and (max-width: 850px) {
  #nis2-form-catchphrase-container {
    // border: 1px solid blue;
    width: 95vw;
  }
}

@media all and (max-width: 650px) {
  #nis2-pop-up-container {
    // border: 1px solid red;
    width: 350px;
  }

  #q2-sectors-of-activity-container {
    // border: 1px solid red;
    grid-template-columns: auto;
  }

  #nis2-submit-form-fields-container {
    // border: 1px solid red;
    grid-template-columns: 100%;
    width: fit-content;
  }

  .nis2-submit-form-label-container {
    // border: 1px solid blue;
    justify-content: center;
    padding-left: 0px;
  }

  .nis2-submit-form-input-container {
    // border: 1px solid blue;
    justify-content: center;
    margin-bottom: 30px;
    padding-top: 0px;
  }
}

@media all and (max-width: 430px) {
  #nis2-form-start-button-container {
    // border: 1px solid red;
    margin-top: 50px;
  }
}
