// Styles for main //
#main-container {
  // border: 1px solid red;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 200px auto;
}
// Styles for main END //

// Styles for outlet //
#outlet-container {
  // border: 1px solid green;
  background-color: $dark-blue3-full-opacity;
  width: 100%;
  min-height: 100vh;
}
// Styles for outlet END //

// Styles for footer //
#footer-container {
  // border: 1px solid green;
  background-color: $dark-blue2-full-opacity;

  width: 100%;
  height: 200px;
}
// Styles for footer END //

@media all and (max-width: 800px){
  #main-container{
    grid-template-columns: none;
    grid-template-rows: auto auto;
  }
}
