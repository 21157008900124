.character-desktop-container {
  // border: 1px solid red;
  background-image: url("../../../images/character-desktop.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 55vh;
  left: 26vw;
  width: 15%;
  height: 50%;
}

.character-desktop-for-navbar-container {
  // border: 1px solid red;
  background-image: url("../../../images/character-desktop.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50vh;
  left: 3vw;
  width: 15%;
  height: 50%;
  z-index: -1;
}

.character-shield-container {
  // border: 1px solid red;
  background-image: url("../../../images/character-shield.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 35vh;
  right: 12.5vw;
  width: 10%;
  height: 35%;
}



///// Media queries //////
@media all and (max-width: 1200px) {
  .character-desktop-container {
    top: 50vh;
    left: 9vw;
    width: 15%;
    height: 25%;
    display: none;
  }

  .character-shield-container {
    top: 50vh;
    left: 77.5vw;
    width: 15%;
    height: 25%;
    display: none;
  }

  .character-desktop-for-navbar-container {
    // border: 1px solid red;
    // background-image: url("../../../images/character-desktop.png");
    // background-size: contain;
    // background-position: center;
    // background-repeat: no-repeat;
    // position: absolute;
    // top: 50vh;
    // left: 3vw;
    // width: 15%;
    // height: 50%;
    // z-index: -1;
    display: none;
  }
}

// @media all and (max-width: 700px) {
//   .character-desktop-container {
//     // border: 1px solid red;
//     top: 50vh;
//     left: 5vw;
//     width: 20%;
//     // height: 10%;
//     display: none;
//   }

//   .character-shield-container {
//     // border: 1px solid red;
//     top: 50vh;
//     left: 75vw;
//     width: 20%;
//     // height: 10%;
//     display: none;
//   }
// }

// @media all and (max-width: 500px) {
//   .character-desktop-container {
//     display: none;
//   }

//   .character-shield-container {
//     display: none;
//   }
// }
