#tool-description-container {
  //   border: 1px solid red;
  background-color: $white-full-opacity;
  width: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  //   justify-content: space-around;
  padding-left: 30px;
  padding-right: 30px;
  //   align-items: flex-start;
}

#tool-description-title-container {
  //   border: 1px solid orange;
  padding: 10px;
  display: flex;
  justify-content: center;
  //   padding-top: 25px;
  margin-top: 0px;
}

#tool-description-image4-container {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

#tool-description-title {
  color: $dark-blue1-full-opacity;
}

#tool-description-text-container {
  //   border: 1px solid blue;
  margin-top: 50px;
}

#tool-description-text {
  color: $dark-blue1-full-opacity;
  text-align: justify;
}

#tool-description-benefits-container {
  //   border: 1px solid pink;
  margin-top: 30px;
  padding-left: 10px;
}

#tool-description-benefits-container > ul {
  //   border: 1px solid red;
}

#tool-description-benefits-container > ul > li {
  color: $dark-blue1-full-opacity;
  text-align: justify;
}

#tool-description-cta-container {
  //   border: 1px solid purple;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

#tool-description-cta-button {
  color: $dark-blue1-full-opacity;

  background-color: $light-blue3-full-opacity;
  font-family: Designosaur, sans-serif;
  color: $white-full-opacity;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.15rem;
  transition: font-size 0.25s;
}

#tool-description-cta-button:hover {
  font-size: 1.25rem;
  background-color: $dark-blue1-full-opacity;
  color: $white-full-opacity;
}
