////////// Animation used in Navbar
@keyframes toolSelectionDisplay {
  0% {
    transform: translateY(-100px);
  }
  20% {
    transform: translateY(10px);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(3px);
  }
  80% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}

.tool-icon-animation {
  animation: toolSelectionDisplay 1.25s linear;
}

//////// Animation used in preaudit progress
@keyframes preauditProgressDisplay {
  0% {
    transform: translateY(10vh);
  }
  60% {
    transform: translateY(-3vh);
  }
  100% {
    transform: translateY(0);
  }
}

.preaudit-progress-animation {
  animation: preauditProgressDisplay 0.5s ease-out 1;
}

//////// Animation used in supplier assesment menu
@keyframes supplierAssesmentThemesDisplay {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}

.supplier-assesment-questions-animation {
  animation-name: supplierAssesmentThemesDisplay;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

////////// Animation used in UserInformations + UserInformationsEditions
// @keyframes iIconBlinking {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// .i-icon-blinking {
//   animation: iIconBlinking 1.5s infinite;
// }

@keyframes tooltipDisplay {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0);
  }
}

.tooltip-animation {
  animation: tooltipDisplay 0.75s ease-in-out;
}

////////// Animation used for cookies banner
@keyframes cookiesBannerDisplay {
  0% {
    transform: translateY(+100px);
  }
  100% {
    transform: translateY(0);
  }
}

.cookies-banner-animation {
  animation: cookiesBannerDisplay 1.25s linear;
}

////////// Animation used for Welcome Text
// @keyframes welcomeTextDisplay {
//   0% {
//     transform: translateY(-100px);
//   }
//   75% {
//     transform: translateY(+15px);
//   }
//   100% {
//     transform: translateY(0);
//   }
// }

// .welcome-text-animation {
//   animation: welcomeTextDisplay 0.75s ease-in-out;
// }

////// Animation used for ACT logo in header ///////
@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logo-rotate {
  animation: rotate360 20s linear infinite;
}

////// Animation used in Backstage //////////
@keyframes RessourceDetailsDisplay {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0);
  }
}

.ressource-details-animation {
  animation: RessourceDetailsDisplay 0.5s ease-in-out; /* 2s pour la durée de l'animation, ease-in-out pour l'accélération/décélération */
}

////// Animation used Supplier Assesment with Token menu //////////
@keyframes displaySupplierDetails {
  0% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}

.supplier-details-animation {
  animation: displaySupplierDetails 0.25s ease-in-out; /* 2s pour la durée de l'animation, ease-in-out pour l'accélération/décélération */
}

@keyframes displaySupplierAssesmentsListing {
  0% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}

.supplier-listing-animation {
  animation: displaySupplierAssesmentsListing 0.25s ease-in-out; /* 2s pour la durée de l'animation, ease-in-out pour l'accélération/décélération */
}

////// Animation used Supplier Assesment with Token menu //////////
@keyframes saveSupplierAssesmentButtonsAnimation {
  0% {
    transform: translateY(-100%);
  }
  // 50% {
  //   transform: translateY(50%);
  // }
  100% {
    transform: translateY(0%);
  }
}

.supplier-assesment-save-button-animation {
  animation: saveSupplierAssesmentButtonsAnimation 0.5s ease-in-out; /* 2s pour la durée de l'animation, ease-in-out pour l'accélération/décélération */
}

//////// Animation used in AUDIT RGPD progress
@keyframes auditRGPDProgressDisplay {
  0% {
    transform: translateY(10vh);
  }
  60% {
    transform: translateY(-3vh);
  }
  100% {
    transform: translateY(0);
  }
}

.audit-rgpd-progress-animation {
  animation: auditRGPDProgressDisplay 0.5s ease-out 1;
}

//////// Animation used in HOMEPAGE
@keyframes textForIcons {
  0% {
    transform: translateY(-20vh);
  }
  60% {
    transform: translateY(+3vh);
  }
  // 95% {
  //   transform: translateY(-2.5vh);
  // }
  100% {
    transform: translateY(0);
  }
}

.text-for-icons-animation {
  animation: textForIcons 0.3s ease-out 1;
}
