#dashboard-container {
  //   border: 1px solid orange;
  //   border: 5px solid orange;
  width: auto;
  min-height: 100vh;
  padding-left: 10px;
  padding-right: 10px;
}

#dashboard-description-container {
  // border: 1px solid red;
  // position:fixed;
  // top:30px;
  // left:41.5vw;
  width: 445px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    text-align: center;
    font-size: 1.25rem;
    font-family: Designosaur, sans-serif;
    margin-bottom: 50px;
  }

  h2 {
    text-align: justify;
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 50px;
  }
}
