// In order to set the Header height and make the Main stick to its bottom
$header-height: 10vh;

//Global opacity
$full-opacity: 1;
$half-opacity: 0.5;

// Global colors
$dark-blue1-full-opacity: rgba(35, 34, 51, $full-opacity);
$dark-blue2-full-opacity: rgba(35, 39, 71, $full-opacity);
$dark-blue3-full-opacity: rgba(43, 44, 64, $full-opacity);
$dark-blue4-full-opacity: rgba(59, 76, 137, $full-opacity);
$light-blue1-full-opacity: rgba(93, 135, 255, $full-opacity);
$light-blue2-full-opacity: rgba(83, 155, 255, $full-opacity);
$light-blue3-full-opacity: rgba(73, 190, 255, $full-opacity);
$violet-full-opacity: rgba(147, 143, 249, $full-opacity);
$green-full-opacity: rgba(19, 222, 185, $full-opacity);
$orange-full-opacity: rgba(248, 172, 39, $full-opacity);
$red-full-opacity: rgba(247, 106, 102, $full-opacity);
$white-full-opacity: rgba(255, 255, 255, $full-opacity);
$black-full-opacity: rgba(0, 0, 0, $full-opacity);

$dark-blue1-half-opacity: rgba(35, 34, 51, $half-opacity);
$dark-blue2-half-opacity: rgba(35, 39, 71, $half-opacity);
$dark-blue3-half-opacity: rgba(43, 44, 64, $half-opacity);
$dark-blue4-half-opacity: rgba(59, 76, 137, $half-opacity);
$light-blue1-half-opacity: rgba(93, 135, 255, $half-opacity);
$light-blue2-half-opacity: rgba(83, 155, 255, $half-opacity);
$light-blue3-half-opacity: rgba(73, 190, 255, $half-opacity);
$violet-half-opacity: rgba(147, 143, 249, $half-opacity);
$green-half-opacity: rgba(19, 222, 185, $half-opacity);
$orange-half-opacity: rgba(248, 172, 39, $half-opacity);
$red-half-opacity: rgba(247, 106, 102, $half-opacity);
$white-half-opacity: rgba(255, 255, 255, $half-opacity);
$black-half-opacity: rgba(0, 0, 0, $half-opacity);
